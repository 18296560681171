<template>
  <div class="px-4 lg:px-9 py-10">
    <h4 class="text-idBlued text-lg font-bold">Staff Upload</h4>

    <section class="flex flex-col lg:flex-row lg:justify-between gap-7">
      <form @submit.prevent="proceed" class="w-full lg:w-2/3">
        <div class="mt-4">
          <label for="mda">
            Select Staff type
            <span class="req">*</span>
          </label>
          <v-select
            id="staffType"
            label="slug"
            :reduce="(b) => b"
            :options="$store.state.admin.staffTypes"
            v-uppercase
            v-model="form.staffType"
            placeholder="-- select --"
          ></v-select>
        </div>

        <div class="mt-4">
          <label for="mda">
            Select MDA
            <span class="req">*</span>
          </label>
          <v-select
            id="mda"
            label="name"
            :reduce="(b) => b"
            :options="$store.state.staff.mdas"
            v-model="form.mda"
            placeholder="-- select --"
          ></v-select>
        </div>

        <div class="mt-5">
          <label for="department" class="text-sm">
            Select Department
            <span class="req">*</span>
          </label>
          <v-select
            id="department"
            label="departmentName"
            :reduce="(b) => b"
            :options="departments"
            :filterable="false"
            v-model="form.department"
            @search="searchDepartments"
            placeholder="-- type here --"
          >
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                No results found for <em>{{ search }}</em
                >.
              </template>
              <span v-else clas="text-gray-400 lowercase text-xs">
                Start typing to search for a department
              </span>
            </template>
          </v-select>
        </div>

        <div class="mt-5">
          <label for="department" class="text-sm">
            Select Bank
            <span class="req">*</span>
          </label>
          <v-select
            id="bank"
            label="bankName"
            :reduce="(b) => b"
            v-model="form.bank"
            :options="$store.state.staff.banks"
            v-uppercase
            placeholder="-- select --"
          ></v-select>
        </div>

        <div class="mt-4">
          <label for="fileUpload">
            Select Excel File
            <span class="req">*</span>
          </label>
          <input
            class="block w-full border border-gray-400 py-1 px-2 rounded"
            type="file"
            name="fileUpload"
            id="fileUpload"
            @change="reviewFile"
            ref="fileUpload"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
          <small v-if="showFileError" class="text-red-600 font-bold">
            {{ fileError }}
          </small>
        </div>

        <div class="mt-7">
          <button
            :disabled="!formReady || proceeding"
            type="submit"
            class="w-full bg-idBlued text-white flex gap-3 py-2.5 justify-center rounded"
          >
            <span>Upload</span>
            <spinner v-if="proceeding"></spinner>
          </button>
        </div>
      </form>

      <div class="w-full lg:w-1/3 px-3 py-2">
        <div class="bg-blue-100 rounded-lg text-idBlued px-3 py-2">
          <h3 class="font-bold text-xl">Instructions</h3>

          <ol class="text-sm mt-2">
            <li>
              1. Make sure to use the provided excel template and adhere
              strictly to its column format. Click to
              <a
                :href="EXCEL_UPLOAD_TEMPLATE"
                download="Staff Onboard Template"
                class="font-bold underline"
              >
                Download template
              </a>
            </li>
            <li>
              2. Make sure the file size is less than
              <span class="font-bold">2mb</span>
            </li>
            <li>
              3. If an MDA is not on the MDA dropdown, use the
              <router-link
                :to="{ name: 'Admin MDA List' }"
                class="font-bold underline"
              >
                MDA List Here
              </router-link>
              to add it
            </li>
            <li>
              4. If a Department is not on the Department dropdown, use the
              <router-link
                :to="{ name: 'Admin Department List' }"
                class="font-bold underline"
              >
                Department List Here
              </router-link>
              to add it
            </li>
            <li>
              5. If a Bank is not on the Bank dropdown, use the
              <router-link
                :to="{ name: 'Admin Bank List' }"
                class="font-bold underline"
              >
                Bank List Here
              </router-link>
              to add it
            </li>
          </ol>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { fetchDepartments } from "@/services/sourceData";

export default {
  name: "OnBoardStaff",

  data() {
    return {
      proceeding: false,
      showFileError: false,
      fileError: null,
      departments: [],
      form: {
        staffType: null,
        mda: null,
        department: null,
        bank: null,
        fileUpload: null,
      },
    };
  },

  computed: {
    formReady() {
      return (
        Boolean(this.form.staffType) &&
        Boolean(this.form.mda) &&
        Boolean(this.form.department) &&
        Boolean(this.form.bank) &&
        Boolean(this.form.fileUpload)
      );
    },
  },

  methods: {
    async searchDepartments(search, loading) {
      if (search.length < 3) {
        return;
      }

      try {
        loading(true);
        this.departments = await fetchDepartments(search);
        loading(false);
      } catch (err) {
        loading(false);
      }
    },

    reviewFile() {
      const file = this.$refs.fileUpload.files[0];

      if (file.size > 2097252) {
        //~2mb
        this.showFileError = true;
        this.fileError = "File size is larger than 2mb";
        return;
      }

      this.form.fileUpload = file;
      console.log(this.form);
      console.log(this.formReady);
    },

    async proceed() {
      if (!this.formReady) {
        this.$swal({
          icon: "error",
          text: "Please fill all fields",
        });
        return;
      }

      try {
        this.proceeding = true;
        const res = await this.$http.postForm(
          "system/on-board-staff",
          this.form
        );

        this.proceeding = false;
        if (!res) {
          return;
        }

        const { data } = res;

        if (data.code === "S200") {
          this.$swal({
            icon: "error",
            text: data.message,
          });
          return;
        }

        this.$swal({
          icon: "success",
          text: data.message,
        });
      } catch (error) {
        this.proceeding = false;
        console.log({ error });
      }
    },
  },
};
</script>

<style scoped>
ol li {
  @apply py-1;
}
</style>
